// App initialization code goes here

$(document).ready(function() {
  initializePickADate();
  initializeMasonry();
  initializeLicenseDropdown();
  initializeNavbarToggle();
  initializeLoginForm();
  initializeRegisterForm();
  initializeForgotPasswordForm();
  var Chartist = require('chartist');
  initializePieCharts();
  initializeAccountCreatedModal();
  initializeRenewalPeriodDropdown();
  // use Fine Uploader S3
  var qq = require('fine-uploader/lib/s3');
  initializeAddCourseForm();
  initializeEditCourseForm();
  initializeDownloadAllButton();
  initializeLoginRequiredModal();
  initializeEditLicenseForms();
  initializeAddLicenseForm();
  initializeEditPersonalInfoForm();
  initializeCreditTypeForm();
  initializePopovers();
  initializeBrowseCoursesLink();
  initializeFinalNotificationModal();
  // initializeNotificationModal();


  function sendAnalyticsEvent(action, category, label, value) {
    if (typeof gtag === 'function') {
      var params = {};
      if (category !== undefined) {
        params.event_category = category;
      }
      if (label !== undefined) {
        params.event_label = label;
      }
      if (value !== undefined) {
        params.value = value;
      }
      gtag('event', action, params);
    }
  }

  function initializeBrowseCoursesLink() {
    $('a.browse-courses').click(function(e) {
      sendAnalyticsEvent('Click Browse Courses Link');
    })
  }

  function initializePopovers() {
    $('[data-toggle="popover"]').popover();
  }

  function initializeCreditTypeForm() {
    $('form#creditTypeForm [type=submit]').click(function (e) {
      e.preventDefault(); // block the traditional submission of the form.

      var credit_type_form = $(this).closest('form');

      // Initialize Submit button.
      $(this).button('loading');

      // Get value of selector.
      var selector_value = $(credit_type_form).find('select#credit_type').first().val();
      var selector_label = $(credit_type_form).find('select#credit_type').find("option:selected").text();

      $('.modal').modal('hide');
      $(this).button('reset');

      var form = $('form#took_course');
      setAddEditHoursFormForCreditType(form, selector_value, selector_label, false);
      $('#addcourse').modal('show');

    });
  }

  function initAddEditFormWithSetName(form, credit_type_machine_name, credit_type_label) {
    // Set credit type.
    $(form).find('select#credit_type').val(credit_type_machine_name);
    // Auto-fill and hide coursename field.
    $(form).find('textarea.field-coursename').text(credit_type_label);
    $(form).find('div.coursename').hide();
    // Set provider to "Board of Behavioral Sciences" and disable field so user cannot edit it.
    $(form).find('input[name="provider"]').val('Board of Behavioral Sciences');
    $(form).find('input[name="provider"]').prop('disabled', true);
    // Set category to Law and Ethics and disable field so user cannot edit it.
    $(form).find('div.category select').val('Law and Ethics');
    $(form).find('div.category select').prop('disabled', true);
    // Hide add category button.
    $(form).find('.add.add-category').hide();
    // Hide remove category button.
    $(form).find('.remove.remove-category').hide();
    // Remove any additional categories that have been added.
    $(form).find('.add-category-hours-section').not('.template').not(':first').remove();
  }

  function initEditFormWithPreFilledCustomName(form, credit_type_machine_name) {
    // Set credit type.
    $(form).find('select#credit_type').val(credit_type_machine_name);
    // Show coursename field.
    $(form).find('div.coursename').show();
    // Enable provider field so user can edit it.
    $(form).find('input[name="provider"]').prop('disabled', false);
    // Enable category field so user can edit it.
    $(form).find('div.category select').prop('disabled', false);
    // Show add category button.
    $(form).find('.add.add-category').show();
    // Show remove category button.
    $(form).find('.remove.remove-category').show();
  }

  function initAddEditFormWithoutPreFilledCustomName(form, credit_type_machine_name) {
    initEditFormWithPreFilledCustomName(form, credit_type_machine_name);

    // Clear coursename field.
    $(form).find('textarea.field-coursename').text('');
    // Clear provider field.
    $(form).find('input[name="provider"]').val('');
    // Unset category field so user can edit it.
    $(form).find('div.category select').val('');
  }

  function setAddEditHoursFormForCreditType(form, credit_type_machine_name, credit_type_label, prefilled_custom_name) {
    switch(credit_type_machine_name) {
      case 'expert_witness':
      case 'subject_matter_expert':
      case 'law_ethics_review_committee':
      case 'board_enforcement_case_review_training':
        initAddEditFormWithSetName(form, credit_type_machine_name, credit_type_label);
        break;

      case 'took_course':
      case 'taught_course':
      default:
        if (prefilled_custom_name) {
          initEditFormWithPreFilledCustomName(form, credit_type_machine_name);
        }
        else {
          initAddEditFormWithoutPreFilledCustomName(form, credit_type_machine_name);
        }
    }
  }

  function initializeEditPersonalInfoForm() {
    $('form#edit-account-form [type=submit]').click(function (e) {
      // Initialize Submit button.
      $(this).button('loading');

      var form = $(this).closest('form');
      submitAjaxForm(form, e);
    });
  }

  function showHideMarriageAndFamilyTherapySetting(form) {
    var license_type = $(form).find("select[name='license_type']").find("option:selected").val();

    if (license_type == "LPCC") {
      $(form).find('.row.marriage_and_family_requirement_row').show();
    }
    else {
      $(form).find('.row.marriage_and_family_requirement_row').hide();
    }
  }

  function initializeMarriageAndFamilyTherapySetting(form) {
    // Initial show/hide.
    showHideMarriageAndFamilyTherapySetting(form);

    $(form).find('select[name="license_type"]').on('change', function (e) {
      showHideMarriageAndFamilyTherapySetting(form);
    });
  }

  function initializeAddLicenseForm() {
    var form = $('form#addLicenseForm');
    initializeMarriageAndFamilyTherapySetting(form);

    $('form#addLicenseForm [type=submit]').click(function (e) {
      $(this).button('loading');

      var form = $(this).closest('form');
      submitAjaxForm(form, e);
    });
  }

  function initializeEditLicenseForms() {
    initializeAreYouSureForm();

    var forms = $('form.form-edit-license');
    $(forms).each(function(index, form) {
      initializeMarriageAndFamilyTherapySetting(form);
    });

    $('form.form-edit-license [type=submit]').click(function (e) {
      // Initialize Submit button.
      $(this).button('loading');

      var form = $(this).closest('form');
      submitAjaxForm(form, e);
    });

    $('form.form-edit-license a.delete').click(function(e) {
      var areYouSureModalID = $(this).data('target');
      $('#' + areYouSureModalID).modal('show');
    });
  }

  function initializeAreYouSureForm() {
    $('form.form-are-you-sure [type=submit]').click(function (e) {
      // Initialize Submit button.
      $(this).button('loading');

      var form = $(this).closest('form');
      submitAjaxForm(form, e);
    });
  }

  function initializeLoginRequiredModal() {
    $('#login-required').modal();
    $('#login-required').on('hide.bs.modal', function (e) {
      window.location = '/';
    })
  }

  function initializeAddEditCourseHoursField() {
    $('.add-category-hours-section, .edit-category-hours-section').not('.template').find('.btn-number').not('.click-event').click(function(e){
        e.preventDefault();
        var type      = $(this).data('type');
        var input = $(this).siblings('input');
        var currentVal = parseFloat(input.val());

        if (!isNaN(currentVal)) {
          if(type == 'minus') {
            if(currentVal > input.attr('min')) {
              input.val(Number.parseFloat(currentVal - 0.5).toFixed(1)).change();
            }
          } else if(type == 'plus') {
            input.val(Number.parseFloat(currentVal + 0.5).toFixed(1)).change();
          }
        } else {
          input.val(0.5);
        }
    }).addClass("click-event");;

    $('.add-category-hours-section, .edit-category-hours-section').not('.template').find('.input-number').not('change-event').change(function() {
      autoEnableDisableDecrementor(this);
    }).addClass('change-event');

    $('.add-category-hours-section, .edit-category-hours-section').not('.template').find('.input-number').each(function(index, el) {
      autoEnableDisableDecrementor(el)
    });

  }

  function autoEnableDisableDecrementor(row) {
    var minValue =  parseInt($(row).attr('min'));
    var valueCurrent = parseInt($(row).val());
    var decrementor = $(row).siblings(".btn-number[data-type='minus']");
    if(valueCurrent > minValue) {
      $(decrementor).prop('disabled', false)
    }
    else {
      $(decrementor).prop('disabled', true);
    }
  }

  function initializeAddCourseHoursSection() {
    $(".add-category-hours-group").each(function() {
      $(this).repeatable_fields({
        row: '.add-category-hours-section',
        row_count_placeholder: '[row-count-placeholder]',
        after_add: initializeAddEditCourseHoursField
      });
    });
  }

  function initializeEditCourseHoursSection() {
    $(".edit-category-hours-group").each(function() {
      $(this).repeatable_fields({
        row: '.edit-category-hours-section',
        row_count_placeholder: '[row-count-placeholder]',
        after_add: initializeAddEditCourseHoursField
      });
    });
  }

  function initializeDownloadAllButton() {
    $('.download-all-button').click(function() {
      $(this).find('.button-contents').button('loading');
    })

    // Set DownloadAll Button to initial state when download is complete
    var Cookies = require('js-cookie/src/js.cookie');

    setTimeout(resetDownloadAllButton, 2000);
    function resetDownloadAllButton () {
      var downloadStarted = Cookies.get('downloadall_starting');
      if (downloadStarted == 1) {
        $('.download-all-button').find('.button-contents').button('reset');
        Cookies.remove('downloadall_starting');
      }
      setTimeout(resetDownloadAllButton, 2000);
    }
  }

  function removeIncompleteHoursFields(form) {
    var rows = $(form).find('.add-category-hours-section, .edit-category-hours-section').not('.template');
    var numRowsShown = 0;
    rows.each(function(index, row) {
      var hours = $(row).find('input').val();
      var category = $(row).find('select').val();
      if ((! hours) || (! category)) {
        $(row).find('.remove').click();
      }
      else {
        numRowsShown += 1;
      }
    });
    // Show at least one Hours field.
    if (numRowsShown == 0) {
      $(form).find('.add-category').click();
    }
  }

  function initializeAddCourseForm() {
    initializeAddCourseHoursSection();
    initializeAddEditCourseHoursField();
    initializeUploadersForAddCourseForms();

    $('form.form-add-credit select#credit_type').on('change', function() {
      var form = $('form.form-add-credit');
      var credit_type_machine_name = $(this).val();
      var credit_type_label = $(this).find("option:selected").text();
      setAddEditHoursFormForCreditType(form, credit_type_machine_name, credit_type_label, false);
    });

    $('form.form-add-credit [type=submit]').click(function (e) {
      $(this).button('loading');

      var form = $(this).closest('form');
      removeIncompleteHoursFields(form);
      submitAjaxForm(form, e);

      // If form submit failed, set up form again based on credit type.
      // This is needed because submitAjaxForm removes the disabled property
      // from all form fields.
      var form = $('form.form-add-credit');
      var credit_type_machine_name = $(form).find('select#credit_type').val();
      var credit_type_label = $(form).find('select#credit_type').find("option:selected").text();
      setAddEditHoursFormForCreditType(form, credit_type_machine_name, credit_type_label, true);
    });
  }

  function submitAjaxForm(form, e) {
    // Enable any disabled fields so they will be submitted.
    $(form).find(':disabled').prop('disabled', false);

    var form_fields = $(form).serializeArray();
    var request_data = {};
    for (var i = 0; i < form_fields.length; i++) {
      var name = form_fields[i]['name'];
      var value = form_fields[i]['value'];
      request_data[name] = value;
    }

    var clickedButton = e.currentTarget;
    if ($(clickedButton).attr('name') == 'delete') {
      request_data['delete'] = true;
    }

    var url = $(form).attr('action');

    injectCSRFTokenIntoAJAXrequest(form);

    $.ajax({
      type: "POST",
      url: url,
      data: JSON.stringify(request_data),
      success: function (data) {
        $(form).find('button').button('success');
        // Let success button display for a bit.
        setTimeout(function(){
          location.reload(true);
        }, 500);
      },
      error: function(jqXHR, textStatus, errorThrown) {
        // Insert errors into form.
        $(form).find('.messages').html(createErrorMarkup(jqXHR.responseJSON.message));
        $(form).find('button').button('reset');

        // Scroll to top of modal so error is visible.
        $(form).closest('.modal').animate({ scrollTop: 0 }, 250);
      }
    });
    e.preventDefault(); // block the traditional submission of the form.
  }

  function initializeEditCourseForm() {
    initializeEditCourseHoursSection();
    initializeAddEditCourseHoursField();
    initializeUploadersForEditCourseForms();
    initializeAreYouSureForm();

    $('form.form-edit-course select#credit_type').on('change', function() {
      var form = $(this).closest('form');
      var credit_type_machine_name = $(this).val();
      var credit_type_label = $(this).find("option:selected").text();
      setAddEditHoursFormForCreditType(form, credit_type_machine_name, credit_type_label, false);
    });

    $('.edit-course-modal').on('show.bs.modal', function(e) {
      var form = $(this).find('form');
      var credit_type_machine_name = $(form).find('select#credit_type').val();
      var credit_type_label = $(form).find('select#credit_type option:selected').text();
      setAddEditHoursFormForCreditType(form, credit_type_machine_name, credit_type_label, true);
    });

    $('form.form-edit-course [type=submit]').click(function (e) {
      // Initialize Submit button.
      $(this).button('loading');

      var form = $(this).closest('form');
      removeIncompleteHoursFields(form);
      submitAjaxForm(form, e);
    });

    $('form.form-edit-course a.delete').click(function(e) {
      var areYouSureModalID = $(this).data('target');
      $('#' + areYouSureModalID).modal('show');
    });
  }

  function initializeRenewalPeriodDropdown() {
    $('#renewal-periods-dropdown-options a').click(function(e) {
      e.preventDefault();
      var url = $(this).attr('href');
      $.ajax({
        type: "GET",
        url: url,
        success: function (data) {
          $('#courses-container').replaceWith(data);
          initializeDownloadAllButton();
          initializePickADate();
          initializeMasonry();
          initializeRenewalPeriodDropdown();
          initializeEditCourseForm();
        },
        error: function(jqXHR, textStatus, errorThrown) {
          // TODO.
        }
      });
    })
  }

  function initializeAccountCreatedModal() {
    // If Account Created modal is closed, take user to the dashboard page.
    $('#account-created').on('hidden.bs.modal', function (e) {
      window.location = '/user';
    });
  }

  function initializePieCharts() {
    $('.ct-chart').each(function() {
      var required = $(this).data('required');
      var completed = $(this).data('completed');
      var label = Math.min(100, Math.round(parseFloat(completed) / parseFloat(required) * 100 )) + '%';

      var data = {
        labels: [label],
        series: [completed]
      };

      var options = {
        startAngle: 0,
        total: required
      };

      var responsiveOptions = [
        ['screen and (min-width: 640px)', {
          labelPosition: 'center',
          labelDirection: 'neutral',
        }]
      ];

      var chart = new Chartist.Pie(this, data, options, responsiveOptions);

    })
  }


  function initializeForgotPasswordForm() {
    $('form[name=forgot_password_form]').submit(function (e) {
      $(this).find('button').button('loading');

      var form = this;
      var url = $(form).attr('action');
      var form_fields = $(form).serializeArray();
      var request_data = {};
      for (var i = 0; i < form_fields.length; i++) {
        var name = form_fields[i]['name'];
        var value = form_fields[i]['value'];
        request_data[name] = value;
      }

      injectCSRFTokenIntoAJAXrequest(form);

      $.ajax({
        type: "POST",
        url: url,
        data: JSON.stringify(request_data),
        success: function (data) {
          $(form).find('button').button('reset');
          $('#password-email-sent .email').text(request_data['email']);
          $('#forgot-password').modal('hide');
          $('#password-email-sent').modal('show');
          $('#password-email-sent .okay-btn').click(function() {
            $('#password-email-sent').modal('hide');
          });
        },
        error: function(jqXHR, textStatus, errorThrown) {
          var errors = jqXHR.responseJSON.response.errors;
          var errors_array = [];
          Object.keys(errors).forEach(function(key) {
            errors_array.push(errors[key][0]);
          });
          // Insert errors into form.
          $(form).find('.messages').html(createErrorMarkup(errors_array.join()));
          $(form).find('button').button('reset');
        }
      });
      e.preventDefault(); // block the traditional submission of the form.
    });


  }

  function initializeRegisterForm() {
    $('form[name=register_user_form]').submit(function (e) {
      $(this).find('button').button('loading');

      var form = this;
      var form_fields = $(form).serializeArray();
      var request_data = {};
      for (var i = 0; i < form_fields.length; i++) {
        var name = form_fields[i]['name'];
        var value = form_fields[i]['value'];
        request_data[name] = value;
      }

      injectCSRFTokenIntoAJAXrequest(form);

      $.ajax({
        type: "POST",
        url: "/create-account",
        data: JSON.stringify(request_data),
        success: function (data) {
          $('#register').modal('hide');
          $('#account-created').modal('show');
          $(form).find('button').button('reset');

          // Send Analytics event.
          sendAnalyticsEvent('User Registration');
        },
        error: function(jqXHR, textStatus, errorThrown) {
          // Insert errors into form.
          $(form).find('.messages').html(createErrorMarkup(jqXHR.responseJSON.message));
          $(form).find('button').button('reset');
        }
      });
      e.preventDefault(); // block the traditional submission of the form.
    });
  }

  function injectCSRFTokenIntoAJAXrequest(form) {
    // Inject our CSRF token into our AJAX request.
    var csrf_token = $(form).find("input[name='csrf_token']").attr('value');
    $.ajaxSetup({
      beforeSend: function(xhr, settings) {
        if (!/^(GET|HEAD|OPTIONS|TRACE)$/i.test(settings.type) && !this.crossDomain) {
          xhr.setRequestHeader("X-CSRFToken", csrf_token);
          xhr.setRequestHeader( "Content-type", "application/json" );
        }
      }
    });
  }

  function initializeNavbarToggle() {
    $('.navbar').on('shown.bs.collapse', function () {
      $(this).find(".navbar-toggle").addClass('nav-shown');
    })
    .on('hidden.bs.collapse', function () {
      $(this).find(".navbar-toggle").removeClass('nav-shown');
   });
  }

  function initializeLoginForm() {
    $('form[name=login_user_form]').submit(function (e) {
      $(this).find('button').button('loading');

      var form = this;
      var form_fields = $(form).serializeArray();
      var request_data = {};
      for (var i = 0; i < form_fields.length; i++) {
        var name = form_fields[i]['name'];
        var value = form_fields[i]['value'];
        request_data[name] = value;
      }

      injectCSRFTokenIntoAJAXrequest(form);

      $.ajax({
        type: "POST",
        url: "/login",
        data: JSON.stringify(request_data),
        success: function (data) {
          window.location.href = "/user";
        },
        error: function(jqXHR, textStatus, errorThrown) {
          var errors = jqXHR.responseJSON.response.errors;
          var errors_array = [];
          Object.keys(errors).forEach(function(key) {
            errors_array.push(errors[key][0]);
            $(form).find('button').button('reset');
          });
          // Insert errors into form.
          $(form).find('.messages').html(createErrorMarkup(errors_array.join()));
        }
      });
      e.preventDefault(); // block the traditional submission of the form.
    });

  }

  function createErrorMarkup(msg) {
    return '<div class="alert alert-danger">' + msg + '</div>';
  }

  function createSuccessMarkup(msg) {
    return '<div class="alert alert-success">' + msg + '</div>';
  }


  function initializePickADate() {
    $('.pickadate:not(#birthday)').each(function(index) {
      var dateField = this;
      $(dateField).pickadate({
        format: 'mm/dd/yyyy',
        selectYears: 100,
        selectMonths: true,
        onRender: function() {
          var openedPicker = $(dateField).next('.picker');
          var monthSelector = $(openedPicker).find('select.picker__select--month');
          var yearSelector = $(openedPicker).find('select.picker__select--year');
          $(monthSelector).insertBefore(yearSelector);
        },
        onSet: function(e) {
          if (e.highlight != null) {
            this.set('select', new Date(Number(e.highlight[0]), Number(e.highlight[1]), Number(e.highlight[2])));
          }
        },
      });
    })

    // We hide the year on the birthday datepicker, and put all the birthdays
    // in 2016 because that is a year with a leap day.
    $('.pickadate#birthday').pickadate({
      format: 'mmmm d',
      formatSubmit: 'mm/dd/yyyy',
      hiddenName: true,
      selectMonths: true,
      onStart: function() {
        var defaultDate = this.get();
        this.set('select', defaultDate, { format: 'mm/dd/yyyy' });
      },
      // Months in javascript are maddeningly zero-indexed, so this is January 1, 2016 to December 31, 2016.
      min: new Date(2016,0,1),
      max: new Date(2016,11,31),
      onSet: function(e) {
        if (e.highlight != null) {
          this.set('select', new Date(Number(e.highlight[0]), Number(e.highlight[1]), Number(e.highlight[2])));
        }
      },
    });
  }

  function initializeMasonry() {
    if ($('.courses').length > 0) {
      var Masonry = require('masonry-layout');
      var msnry = new Masonry( '.courses', {
        itemSelector: '.course',
        columnWidth: 350,
        gutter: 30,
        horizontalOrder: true,
        fitWidth: true,
        transitionDuration: '0.2s'
      });
    }
  }


  function initializeLicenseDropdown() {
    $('select#dashboard-license').change(function() {
      window.location = this.value;
    })
  }

  function initializeUploadersForAddCourseForms() {

    var uploaders = [];
    $('.form-add-credit .certificate-uploader').each(function(key, val) {

      var form = $(this).closest('form');
      var id = $(val).attr('id');
      var csrf_token = $(val).closest('form').find("input[name='csrf_token']").attr('value');
      var user_id = $('#user-data').data('user-id');
      var license_id = $('#user-data').data('license-id');
      var s3_key_dir = user_id + '/' + license_id + '/temp';
      var s3_endpoint = $('#s3-data').data('s3-endpoint');
      var s3_access_key = $('#s3-data').data('s3-access-key');

      uploaders.push(new qq.s3.FineUploader({
        debug: true,
        element: val,
        template: 'qq-template',
        objectProperties: {
          key: function(){ return s3_key_dir + '/' + Date.now(); }
        },
        request: {
          endpoint: s3_endpoint,
          accessKey: s3_access_key
        },
        signature: {
          endpoint: '/license/s3/sign/' + license_id,
          customHeaders: {
            'X-CSRFToken': csrf_token
          }
        },
        uploadSuccess: {
          endpoint: '/license/s3/success',
          customHeaders: {
            'X-CSRFToken': csrf_token
          }
        },
        validation: {
           //        itemLimit: 1
        },
        chunking: {
          enabled: false,
          concurrent: {
            enabled: true
          }
        },
        resume: {
          enabled: true
        },
        retry: {
          //       enableAuto: true,
          showButton: true
        },
        callbacks: {
          onSubmit: function(id) {
            // Hide Any pre-existing upload previews.
            $(form).find('.certificate-uploader .qq-upload-list').children().hide();
          },
          onComplete: function(id, name, response) {
            if (response.success) {
              $(form).find('.certificate_s3_key').val(response.key);
            }

            // Edit text of upload button.
            $('#certificate-uploader .qq-upload-button .button-text').text('Replace File');

          },
          onSessionRequestComplete: function(response, success, xhrOrXdr) {
            //this.addInitialFiles(response);
          }
        }
      }));
    });
  }

  function initializeUploadersForEditCourseForms() {

    var uploaders = [];
    $('.form-edit-course .certificate-uploader').each(function(key, val) {
      var id = $(val).attr('id');
      var csrf_token = $(val).closest('.form-edit-course').find("input[name='csrf_token']").attr('value');
      var course_id = $(val).closest('.form-edit-course').attr('id');
      course_id = course_id.split('_');
      course_id = course_id[1];
      var user_id = $('#user-data').data('user-id');
      var license_id = $('#user-data').data('license-id');
      var uuid_hidden_field_id = 'certificate_s3_key_' + course_id;
      var s3_key_dir = user_id + '/' + license_id + '/' + course_id + '/temp';
      var uploader_id = uuid_hidden_field_id + '-uploader';
      var s3_endpoint = $('#s3-data').data('s3-endpoint');
      var s3_access_key = $('#s3-data').data('s3-access-key');

      uploaders.push(new qq.s3.FineUploader({
        debug: true,
        element: val,
        template: 'qq-template',
        objectProperties: {
          key: function(){ return s3_key_dir + '/' + Date.now(); }
        },
        request: {
          endpoint: s3_endpoint,
          accessKey: s3_access_key
        },
        session: {
          endpoint: '/license/s3/existing/' + license_id,
          params: {
            'course_id': course_id,
          }
        },
        signature: {
          endpoint: '/license/s3/sign/' + license_id,
          customHeaders: {
            'X-CSRFToken': csrf_token
          }
        },
        uploadSuccess: {
          endpoint: '/license/s3/success',
          customHeaders: {
            'X-CSRFToken': csrf_token
          }
        },
        validation: {
          sizeLimit: 15000000

           //        itemLimit: 1
        },
        chunking: {
          enabled: false,
          concurrent: {
            enabled: true
          }
        },
        resume: {
          enabled: true
        },
        retry: {
          //       enableAuto: true,
          showButton: true
        },
        callbacks: {
          onSubmit: function(id) {
            // Hide Any pre-existing upload previews.
            $('#' + uploader_id + ' .qq-upload-list').children().hide();
          },
          onComplete: function(id, name, response) {
            if (response.success) {
              $('#' + uuid_hidden_field_id).val(response.key);
            }
          },
          onSessionRequestComplete: function(response, success, xhrOrXdr) {
            //this.addInitialFiles(response);
          }
        }
      }));

      // Edit text of upload button.
      $('#' + uploader_id + ' .qq-upload-button .button-text').text('Replace File');

    });
  }

  function initializeFinalNotificationModal() {
    if($('#final-notification-modal').length) {
      $('#final-notification-modal a div.close-modal').on('click', function(e) {
        setTimeout(function() {
          initializeNotificationModal();
        });
      });

      var cookieName = 'final_notice_shown';
      var cookie = getCookie(cookieName);
      if (cookie == "") {
        $('#final-notification-modal').modal();

        setCookie(cookieName, 24);
      } else {
        initializeNotificationModal();
      }
    }
  }

  function initializeNotificationModal() {
    if ($('#notification-modal').length) {
      var cookieName = 'notice_last_shown';
      var cookie = getCookie(cookieName);
      if (cookie == "") {
        $('#notification-modal').modal();
        // Set the cookie to expire in 1 week so the popup will appear once a
        // week.
        setCookie(cookieName, 168);
      }
    }
  }

  function setCookie(cookieName, expirationHours) {
    var d = new Date();
    d.setTime(d.getTime() + (expirationHours*60*60*1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cookieName + "=1; path=/;" + expires;
  }

  function getCookie(cookieName) {
      var name = cookieName + "=";
      var ca = document.cookie.split(';');
      for(var i=0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0)==' ') c = c.substring(1);
          if (c.indexOf(name) != -1) return c.substring(name.length, c.length);
      }
      return "";
  }

});

